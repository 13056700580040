import APIRequest from './_api_request'

const LIMIT = 40
const HISTORY_LIMIT = 40

export default class Api {

    constructor(location) {
        this.api_path_location = location
    }

    // --------------------------
    // -------- CREATE ----------
    // --------------------------

    async create(data) {
        return await APIRequest.postApiRequest(`${this.api_path_location}`, data)
    }

    async createCurrentRelatedDocusign(id, data) {
        return await APIRequest.postApiRequest(`${this.api_path_location}${id}/docusign/`, data)
    }

    // --------------------------
    // ---------- READ ----------
    // --------------------------

    async findAll() {
        return await APIRequest.getApiRequest(`${this.api_path_location}`)
    }
    async findAll(page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?page=${page}&limit=${LIMIT}`)
    }    
    async findAll(page, sort_order = "desc", sort_by = "ID") {
        return await APIRequest.getApiRequest(`${this.api_path_location}?page=${page}&limit=${LIMIT}&sort_order=${sort_order}&sort_by=${sort_by}&includeDescription=true`)
    }
    async findAll(page, sort_order = "desc", sort_by = "ID", filters = "") {
        return await APIRequest.getApiRequest(`${this.api_path_location}?page=${page}&limit=${LIMIT}&sort_order=${sort_order}&sort_by=${sort_by}&includeDescription=true${filters}`)
    }
    async findAllByCode(code, page, sort_order = "desc", sort_by = "ID", filters = "") {
        return await APIRequest.getApiRequest(`${this.api_path_location}codesearch/${code}?page=${page}&limit=${LIMIT}&sort_order=${sort_order}&sort_by=${sort_by}&includeDescription=true${filters}`)
    }
    async findAllTransactionOptions() {
        return await APIRequest.getApiRequest(`${this.api_path_location}options/`)
    }
    async find(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}`)
    }
    async find(id,options) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}?${options}`)
    }
    async findSecret(id, attr) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/secret?attr=${attr}`)
    }

    async findParticipation(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/participation`)
    }
    async findAllUnlimited(filters = "") {
        return await APIRequest.getApiRequest(`${this.api_path_location}?limit=*${filters}`)
    }

    async findWithOptions(options) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?${options}`)
    }
        
    async findWithPermissions(id){
      return await APIRequest.getApiRequest(`${this.api_path_location}${id}?include_permissions=true`)
    }

    async findWithOptionsAndPermissions(options) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?include_permissions=true&${options}`)
    }

    async findWithPermissionsAndDescriptor(id){
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}?includeDescription=true&include_permissions=true&with=player,team,player.agent`)
    }

    async findPending(id) {
        var data = await APIRequest.getApiRequest(`${this.api_path_location}${id}/history/edit?includeDescription=true`)
        if (data.data) { return data.data }
        return data
    }
    async findPendingWithPermissions(id) {
      var data = await APIRequest.getApiRequest(`${this.api_path_location}${id}/history/edit?includeDescription=true&include_permissions=true&with=player,team,player.agent`)
      if (data.data) { return data.data }
      return data
    }
    async findEdit(id) {
        var data = await APIRequest.getApiRequest(`${this.api_path_location}${id}/history/edit`)
        if (data.data) { return data.data }
        return data
    }
    async findPendingDiff(id) {
        var data = await APIRequest.getApiRequest(`${this.api_path_location}${id}/history/pending`)
        if (data.data) { return data.data }
        return data
    }

    async findHistory(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/history`)
    }    
    async findHistory(id, params, page) {
    if (!params) { params = '' }
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/history?page=${page}&limit=${HISTORY_LIMIT}${params}`)
    }
    async findPlayerFixtures(id, params = {}) {
        let url = `${this.api_path_location}${id}`;
        if (params.year) {
            url += `?year=${params.year}`;
        }
        if (params.team) {
            // If the URL already contains a query parameter, add '&', otherwise add '?'
            url += (url.includes('?') ? '&' : '?') + `team_id=${params.team}`;
        }
        return await APIRequest.getApiRequest(url);
    }

    //transactional state
    async checkAnyPendingTransactions() {
        return await APIRequest.getApiRequest(`${this.api_path_location}?transaction_state=pending&page=1&limit=${HISTORY_LIMIT}`)
    }
    async findAllPendingTransactions(page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?transaction_state=pending&page=${page}&limit=${HISTORY_LIMIT}`)
    }
    async findAllAcceptedTransactions(page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?transaction_state=accepted&page=${page}&limit=${HISTORY_LIMIT}`)
    }

    //distribution state
    async findAllPendingDistribution() {
        return await APIRequest.getApiRequest(`${this.api_path_location}?transaction_state=accepted&distribution_state=pending&limit=1000`)
    }
    async findAllCompletedDistribution(page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?distribution_state=distributed&page=${page}&limit=${HISTORY_LIMIT}`)
    }
    async findAllNotDistributed(page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}?distribution_state=pending_dsr&page=${page}&limit=${HISTORY_LIMIT}`)
    }

    // Roster for Team
    async findRosterWithPlayerAndTeam(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/roster?with=player,team&limit=*`)
    }

    async findWithPlayerAndTeam(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}?with=player,team&limit=*`)
    }
    async findHistoryWithPlayerAndTeam(id, params, page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/history?with=player,team&page=${page}&limit=${HISTORY_LIMIT}${params}`)
    }
    async findPendingDiffWithPlayerAndTeam(id) {
        var data = await APIRequest.getApiRequest(`${this.api_path_location}${id}/history/pending?with=player,team`)
        if (data.data) { return data.data }
        return data
    }
    // Contracts for Team
    async getTeamContracts(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/contracts?with=player&limit=*`)
    }
    //Roster Summary
    async getRosterSummary() {
        return await APIRequest.getApiRequest(`${this.api_path_location}summary/new`)
    }

    // Find player in First / Last name %ilike%
    async findAllPlayerNameSearch(search, page, sort_order = "desc", sort_by = "ID") {
        if (!search) return []
        return await APIRequest.getApiRequest(`${this.api_path_location}fullnamesearch/${search}?page=${page}&limit=${LIMIT}&sort_order=${sort_order}&sort_by=${sort_by}`)
    }

    // Find agent in First / Last name %ilike%
    async findAllAgentNameSearch(search, page, sort_order = "desc", sort_by = "ID") {
        if (!search) return []
        return await APIRequest.getApiRequest(`${this.api_path_location}fullnamesearch/${search}?page=${page}&limit=${LIMIT}&sort_order=${sort_order}&sort_by=${sort_by}`)
    }

     // Find contract no %ilike%
     async findAllContractNoSearch(search, page, sort_order = "desc", sort_by = "ID") {
        if (!search) return []
        return await APIRequest.getApiRequest(`${this.api_path_location}contractnosearch/${search}?page=${page}&limit=${LIMIT}&sort_order=${sort_order}&sort_by=${sort_by}&includeDescription=true`)
    }

    async findAllPlayerContracts(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/contracts`)
    }
    
    // reports
    async findDistributedTransactions(id, params, page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}?page=${page}&limit=${HISTORY_LIMIT}${params}`)
    }
    
    async findAllRelatedDocusign(id, page) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/docusign?page=${page}&limit=${LIMIT}`)
    }

    async findCurrentRelatedDocusign(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/docusign/current`)
    }

    async findReport(reportType) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${reportType}`)
    }

    // FIXTURE ROSTERS
    async findGameRosters(id) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/rosters?include_permissions=true`)
    }

    async findGameRostersWithDetails(id,group) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/rosters/${group}/?with=team,roster`)
    }

    async findGameRoster(id, path) {
        return await APIRequest.getApiRequest(`${this.api_path_location}${id}/rosters/${path}`)
    }

    // --------------------------
    // -------- UPDATE ----------
    // --------------------------

    async update(id, data) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}`, data)
    }

    async overwrite(id, data) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}?overwrite`, data)
    }

    async updateWithApproval(id, data) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}?requires_approval`, data)
    }    

    async acceptTransaction(id) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}?transaction_state=accepted`)
    }
    async unacceptTransaction(id) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}?transaction_state=pending`)
    }    
    async flagTransaction(id, flag = true) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}?flagged=${flag}`)
    }
    
    async distribute(id, data) {
        return await APIRequest.postApiRequest(`${this.api_path_location}${id}/distribute?includeNeglist=true`, data) // { dl: [], message: String }
    }
    async distributeRoster(id, data) {
        return await APIRequest.postApiRequest(`${this.api_path_location}${id}/distribute?includeNeglist=false`, data) // DS without neglist
    }
    async markDistributed(id) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}?distribution_state=distributed`)
    }
    async markAllDistributed(data) {
        return await APIRequest.postApiRequest(`${this.api_path_location}markalldistributed`, data) // { dl: [] }
    }    

    async sendCurrentRelatedDocusignForm(id, file) {
        let formData = new FormData();
        formData.append("file", file);
        return await APIRequest.postApiRequest(`${this.api_path_location}${id}/docusign/current/sign`, formData)
    }
        
    async findContractsWithPlayersAndTeams(page, sort_order = "desc", sort_by = "ID", filters = "", limit = LIMIT, sort_by_optional = '') {
        return await APIRequest.getApiRequest(`${this.api_path_location}/?with=player,team,player.contracts&page=${page}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&includeDescription=true${filters}`)
    }

    async voidCurrentRelatedDocusign(id) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}/docusign/current/void`)
    }

    async updateGameRoster(id, group, data) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}/rosters/${group}`, data)
    }

    async finalizeDraft(id, data) {
        return await APIRequest.putApiRequest(`${this.api_path_location}${id}/finalize`, data)
    }

    // --------------------------
    // --------- DELETE ---------
    // --------------------------
    
    async delete(id) {
        return await APIRequest.deleteApiRequest(`${this.api_path_location}${id}`)
    }
   
}
