import Api from './_api'
import CommentApi from './_comment_api'
import LookupApi from './_lookup_api'

const TEAMS_SERVICE = new Api('/api/teams/')
const VENUES_SERVICE = new Api('/api/venues/')
const AGENTS_SERVICE = new Api('/api/agents/')
const ROSTERS_SERVICE = new Api('/api/rosters/')
const PLAYERS_SERVICE = new Api('/api/players/')
const COLLEGES_SERVICE = new Api('/api/colleges/')
const CONTRACTS_SERVICE = new Api('/api/contracts/')
const TRANSACTIONS_SERVICE = new Api('/api/transactions/')
const ROSTER_SERVICE = new Api('/api/rosters/')
const ROSTER_PLAYERS_SERVICE = new Api('/api/rosterplayers/')
const REPORTS_SERVICE = new Api('/api/reports/')
const ADDENDUMS_SERVICE = new Api('/api/contractphrases/')
const ADDENDUM_GROUPS_SERVICE = new Api('/api/contractphrasegroups/')
const FIXTURES_SERVICE = new Api('/api/fixtures/')
const FIXTURE_ROSTERS_SERVICE = new Api('/api/fixturerosters/')
const SEASONS_SERVICE = new Api('/api/seasons/')
const DAILYSTATUS_SERVICE = new Api('/api/dailystatusreports/')
const PLAYER_REQUESTS_SERVICE = new Api('/api/player_requests/')
const DUES_SERVICE = new Api('/api/player-dues-report/')
const PENSIONS_SERVICE = new Api('/api/team-pensions/season-team/')
const PLAYER_FIXTURES_SERVICE = new Api('/api/players/fixtures/')
// For now we want the route that finds transactions by player id not the one that finds all transactions by season
const LEDGER_TRANSACTIONS_SERVICE = new Api('/api/ledger/players/')
const DRAFT_PLAYERS_SERVICE = new Api('/api/draftplayers/')
const DRAFT_SELECTION_SERVICE = new Api('/api/draftselection/')
const COMMENT_SERVICE = new CommentApi()
const LOOKUP_SERVICE = new LookupApi()

const VERSION_SERVICE = new Api('/api/version/')

export {
  TEAMS_SERVICE,
  VENUES_SERVICE,
  AGENTS_SERVICE,
  ROSTERS_SERVICE,
  PLAYERS_SERVICE,
  COLLEGES_SERVICE,
  CONTRACTS_SERVICE,
  TRANSACTIONS_SERVICE,
  ROSTER_SERVICE,
  ROSTER_PLAYERS_SERVICE,
  REPORTS_SERVICE,
  COMMENT_SERVICE,
  LOOKUP_SERVICE,
  ADDENDUMS_SERVICE,
  ADDENDUM_GROUPS_SERVICE,
  VERSION_SERVICE,
  FIXTURES_SERVICE,
  FIXTURE_ROSTERS_SERVICE,
  SEASONS_SERVICE,
  DAILYSTATUS_SERVICE,
  PLAYER_REQUESTS_SERVICE,
  DUES_SERVICE,
  PENSIONS_SERVICE,
  PLAYER_FIXTURES_SERVICE,
  LEDGER_TRANSACTIONS_SERVICE,
  DRAFT_PLAYERS_SERVICE,
  DRAFT_SELECTION_SERVICE
}
